@use 'node_modules/@angular/material' as mat;
@import 'theme';
@import 'material-overrides';
@import '~reset-css/sass/reset';
@import 'variables';
@import 'typography';
@import 'utils';

@include mat.all-component-themes($app-theme);

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 360px;
  background-color: $app-background-colour;
}

* {
  box-sizing: border-box;
}
