@import 'variables';

.index-manager {
  .mat-ink-bar {
    height: 5px;
  }

  button {
    border-radius: 2px;
  }

  .mat-card {
    border-radius: 2px;
  }

  index-manager-card {
    mat-card {
      padding: 24px;
    }
    .mat-card-title {
      margin: 0px 0px 16px;
    }
  }

  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  index-manager-header {
    .mat-tab-links {
      align-items: center;
    }

    .mat-tab-link {
      height: $header-height;
      line-height: $header-height;
      font-size: $typography-scale * 1.2rem;
      @media (max-width: 970px) {
        min-width: 72px;
      }
    }

    .mat-tab-label-active {
      opacity: 1;
    }
  }

  index-managed-sidebar {
    .mat-tab-links {
      align-items: center;
    }

    .mat-tab-link {
      height: $header-height;
      line-height: $header-height;
      font-size: $typography-scale * 1.2rem;
      @media (max-width: 970px) {
        min-width: 72px;
      }
    }

    .mat-tab-label-active {
      opacity: 1;
    }
  }
}

index-manager-add-documents-modal {
  .mat-tab-label-content {
    font-size: 16px;
  }

  .mat-tab-group {
    .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  .mat-dialog-actions {
    padding: 12px 0px;
  }
}

index-manager-select-all-checkbox-list {
  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      width: 100%;

      .mat-checkbox-inner-container {
        margin-left: 0;
      }
    }
  }
}
