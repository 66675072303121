$app-background-colour: #eee;

// Typography
$primary-font-family: Helvetica, Arial, sans-serif;
$typography-base-font-size: 16; // in pixels
$typography-base-line-height: 1.6; // how large the line height is as a multiple of font size
$typography-leading: $typography-base-line-height * 0.6rem;
$typography-scale: 1; // Rate of growth for headings
$table-line-height: $typography-base-line-height * $typography-base-font-size - 0px;

// Header
$header-line-height: 40px;
$header-v-padding: 8px;
$header-height: $header-line-height + 2 * $header-v-padding;
$header-gutter: 1rem;

// Sidebar
$sidebar-width: 200px;

// Actionbar
$draft-action-bar-line-height: 40px;
$draft-action-bar-v-padding: 14px;
$draft-action-bar-height: $draft-action-bar-line-height + 2 * $draft-action-bar-v-padding;
$draft-action-bar-gutter: 2em;
$draft-action-bar-small-screen-breakpoint: 840px;

// Draft body
$draft-body-publication-row-small-screen-breakpoint: 900px;
$draft-body-gutter: 2em;

// Draft drawer
$draft-drawer-padding: 1em;
$draft-drawer-button-height: 40px;

// Material
$material-divider-colour: rgb(224, 224, 224);

// Summary Divider
$summary-divider-offset: 68px;
$summary-divider-padding: 5px 0px;
$summary-divider-gutter: $draft-body-gutter;
$publication-text-padding: 5px;
$summary-divider-colour: #b4b2b2;

// Overview page
$overview-body-gutter: 2em;
$validation-icon-width: 2em;

// Add documents modal
$add-documents-modal-guidance-text-padding: 0 1em 1em 1em;

// Archive Publications page
$publications-body-gutter: 2em;

// Archive Indexes page
$indexes-gutter: 2em;

// Publication progress page
$publication-progress-body-gutter: 2em;
