.flex-grow {
  flex: 1;
}

.icon-button {
  mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}
